<template>
   <div class="">
      <div class="Grid">
         <div class="Grid-column">
            <div class="Page-actionBar">
               <div class="Page-actionBarStart">
                  <router-link to="/classes" class="typeLink-actionBack">All classes</router-link>
               </div>

               <div class="Page-actionBarEnd" v-if="typeof this.sessionData !== 'undefined' && typeof this.sessionData.id !== 'undefined'">
                  <a href="#" class="typeLink-action mr++" @click.prevent="goToPreview">Preview class</a>
                  <a href="#" class="typeLink-action" @click.prevent="deleteSession">Delete class</a>
               </div>
            </div>
         </div>
      </div>

      <section class="Page-panel">
         <div class="Grid">
            <div class="Grid-column">
               <vue-tabs>
                  <vue-tab name="Content" :selected="true">
                     <section class="Grid">
                        <div class="Grid-column s-3/4--tab s-2/3--lap">
                           <form @submit.prevent="createSession" class="Form--extraSpace">
                              <div class="Form-field">
                                 <label for="" class="Form-label">State</label>
                                 <div class="mt">
                                    <div class="Form-inputPill">
                                       <input type="radio" id="stateDraft" name="state" value="draft" v-model="newSession.state">
                                       <label for="stateDraft">Draft</label>
                                    </div>

                                    <div class="Form-inputPill">
                                       <input type="radio" id="stateActive" name="state" value="active" v-model="newSession.state">
                                       <label for="stateActive">Active</label>
                                    </div>

                                    <div class="Form-inputPill">
                                       <input type="radio" id="stateArchived" name="state" value="archived" v-model="newSession.state">
                                       <label for="stateArchived">Archived</label>
                                    </div>
                                 </div>
                              </div>

                              <div class="Form-field">
                                 <label for="" class="Form-label">Date and time</label>
                                 <flat-pickr v-model="sessionDate" :config="{enableTime: true, time_24hr: true}" class="Form-input" placeholder="Select date and time"></flat-pickr>
                              </div>

                              <!-- <div class="Form-field">
                                 <label for="" class="Form-label">Time</label>
                                 <input type="time" class="Form-input" v-model="sessionTime" required>
                              </div> -->

                              <div class="Form-field">
                                 <label for="" class="Form-label">Type</label>

                                 <div class="mt">
                                    <div class="Form-inputPill">
                                       <input type="radio" id="typeLive" name="type" value="Live" v-model="newSession.type">
                                       <label for="typeLive">Live</label>
                                    </div>

                                    <div class="Form-inputPill">
                                       <input type="radio" id="typeRec" name="type" value="Recorded" v-model="newSession.type">
                                       <label for="typeRec">Recorded</label>
                                    </div>
                                 </div>

                                 <div class="Form-field mt++" v-if="recordedVideoFile">
                                    <label for="" class="Form-label">Video File</label>
                                    <div class="mt">
                                       <input type="file" @change="videofileChange">
                                    </div>
                                 </div>
                              </div>

                              <div class="Form-field">
                                 <label for="" class="Form-label">Subject</label>

                                 <div class="mt">
                                    <div class="Form-inputPill" v-for="(item, index) in subjectList">
                                       <input type="radio" :id="'subject_'+item" name="subject" :value="item" v-model="newSession.subject">
                                       <label :for="'subject_'+item">{{item}}</label>
                                    </div>
                                 </div>
                              </div>

                              <div class="Form-field">
                                 <label for="sessionIntro" class="Form-label">Introduction</label>
                                 <textarea class="Form-inputMulti" id="sessionIntro" v-model="newSession.introduction"></textarea>
                              </div>

                              <div class="Form-field">
                                 <label for="sessionDetails" class="Form-label">Details</label>
                                 <textarea class="Form-inputMulti" id="sessionDetails" v-model="newSession.details"></textarea>
                              </div>

                              <div class="Form-field">
                                 <label for="" class="Form-label">Equipment needed</label>

                                 <div class="mt">
                                    <div class="Form-inputPill" v-for="(item, index) in equipmentList">
                                    <!-- <div class="Form-inputPill" v-for="(item, index) in newSession.equipment"> -->
                                       <input type="checkbox" :id="'equipment_'+index" :name="item" :value="item" v-model="newSession.equipment">
                                       <label :for="'equipment_'+index">{{item}}</label>
                                    </div>
                                 </div>
                              </div>

                              <div class="Form-field">
                                 <label for="" class="Form-label">Downloadable files (Worksheets etc)</label>

                                 <div class="" v-if="typeof newSession.worksheets !== 'undefined' && newSession.worksheets.length > 0">
                                    <ul class="mb+">
                                       <li v-for="(worksheet, index) in newSession.worksheets" v-if="typeof worksheet.label !== 'undefined'">{{worksheet.label}} - {{worksheet.downloadUrl}}</li>
                                    </ul>
                                 </div>

                                 <div class="Card mt mb+" v-show="worksheets.files.length > 0">
                                    <div class="Form-field--flex" v-for="(worksheet, index) in worksheets.files">
                                       <div class="s-1/2--tab">
                                          <label for="" class="Form-label">File label</label>
                                          <input type="text" class="Form-input" v-model="worksheet.label">
                                       </div>

                                       <div class="ml-g+">
                                          <label for="" class="Form-label">File</label>
                                          <div class="mt">
                                             <input type="file" @change="worksheetFileChange" :data-index="index">
                                          </div>
                                       </div>

                                       <!-- <div class="ml-g+ flex-alignBottom">
                                          <button @click.prevent="removeDownloadFile(index)" class="button--s button--ghost">Delete</button>
                                       </div> -->
                                    </div>
                                 </div>

                                 <div class="mt">
                                    <button class="button--s" @click.prevent="addWorksheet">Add file</button>
                                 </div>
                              </div>

                              <div class="Form-field" v-show="!sessionCreated">
                                 <input type="submit" class="Form-submit" :value="submitLabel">
                                 <router-link to="/classes" class="button button--ghost">Cancel</router-link>
                              </div>

                              <div class="Form-field" v-show="sessionCreated">
                                 <div class="typeCopy">Class created</div>
                                 <div class="typeCopy-s" v-show="fileUploadProgress < 100">Please wait while your files upload</div>
                                 <div class="typeCopy-s" v-show="fileUploadProgress > 0"><strong>{{fileUploadProgress}}% Uploaded</strong></div>


                                 <div class="typeCopy-s" v-show="fileUploadProgress == 'Upload complete'">
                                    <strong>Video uploaded</strong> - <router-link to="/classes" class="typeLink-action">Continue</router-link>
                                 </div>

                                 <div v-show="worksheets.uploadComplete">Worksheets uploaded</div>
                              </div>
                           </form>
                        </div>
                     </section>
                  </vue-tab>

                  <vue-tab name="Manage">
                     <div class="Grid">
                        <div class="Grid-column s-3/4--tab s-2/3--lap">
                           <div class="">
                              <div class="typeTitle">Comments</div>
                              <session-comments :sessionIdProp="sessionId" :restrictChatProp="true" :restrictCommentsProp="false"></session-comments>
                           </div>
                        </div>

                        <div class="Grid-column s-1/4--tab s-1/3--lap">
                           <div class="Card">
                              <div class="mb+" v-if="isLiveSession && typeof newSession.video.meta.id !== 'undefined'">
                                 <div class="typeCopy-s mt"><strong>Stream Key</strong> <br> {{newSession.video.meta.streamKey}}</div>
                                 <div class="typeCopy-s mt"><strong>Server URL</strong> <br> rtmp://global-live.mux.com:5222/app</div>
                                 <div class="typeCopy-s mt"><strong>Status</strong> <br> {{newSession.video.live.status}}</div>
                              </div>

                              <div>
                                 <div class="aspectVideo aspectVideo--iframe">
                                    <video-player class="aspectVideo-video" v-if="typeof newSession.video !== 'undefined'" :previewVideo="true" :videoSource="newSession.video" :poster="'poster-'+newSession.subject.replace(/\s+/g, '-').toLowerCase()+'.png'" :videoType="newSession.type" :scheduledFor="newSession.scheduledFor"></video-player>
                                 </div>
                                 <div class="" v-if="isLiveSession && typeof newSession.video.meta.id !== 'undefined'">
                                    <div class="typeCopy-s" v-show="newSession.video.live.states.connected">Connected</div>
                                    <div class="typeCopy-s" v-show="newSession.video.live.states.disconnected">Disconnected</div>
                                    <div class="typeCopy-s" v-show="newSession.video.live.states.recording">Recording</div>
                                    <div class="typeCopy-s" v-show="newSession.video.live.states.active">Active</div>
                                    <!-- <div class="typeCopy-s" v-show="newSession.video.live.states.idle">Idle</div> -->
                                    <div class="typeCopy-s" v-show="newSession.video.asset.states.live_stream_completed">Stream complete</div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </vue-tab>
               </vue-tabs>
            </div>
         </div>
      </section>
   </div>
</template>

<script>
   import Vue from "vue";
   import { mapState } from 'vuex';

   const fire = require('./../firebaseConfig.js')
   import axios from 'axios';
   import flatPickr from 'vue-flatpickr-component';
   import 'flatpickr/dist/flatpickr.css';

   import VideoPlayer from './video-player.vue';
   import SessionComments from './../components/session-comments.vue';
   import VueTabs from './../components/vue-tabs.vue';
   import VueTab from './../components/vue-tab.vue';

   export default {
      components: {
         VideoPlayer,
         flatPickr,
         SessionComments,
         VueTabs,
         VueTab
      },

      props: {
         sessionData: Object
      },

      data() {
         return {
            sessionId: '',

            sessionCreated: false,
            sessionDate: '',
            sessionTime: '',
            equipment: [],
            recordedVideoFile: false,
            videoFile: '',
            fileUploadProgress: 0,

            worksheets: {
               files: [],
               queue:[],
               uploaded: [],
               concurrentMax: 3,
               count: 0,
               uploadComplete: false
            },

            newSession: {
               subject: '',
               type: '',
               teacher: '',
               introduction: '',
               details: '',
               scheduledFor: '',
               equipment: [],
            },

            equipmentList: [
               'Pen and Paper',
               'Calculator',
               'Pencil',
               'Thesaurus',
               'Dictionary',
               'Plain paper',
               'Coloured paper',
               'Scissors',
               'Glue',
               'Paint',
               'Coloured pens',
               'Coloured pencils',
               'Coloured pens or pencils',
               'Dancing feet!',
               'Ingredients'
            ],

            subjectList: [
               'English',
               'Maths',
               'Reading',
               'Word of the Day',
               'Grammar',
               'Movement',
               'Topic',
               'Phonics'
            ]
         }
      },

      watch: {
         sessionData: {
            immediate: true,
            handler() {
               if (typeof this.sessionData !== 'undefined' && typeof this.sessionData.id !== 'undefined') {
                  this.setSessionData();
               }
            }
         },

         sessionType() {
            this.recordedVideoFile = (this.sessionType == 'Recorded');
         }
      },


      computed: {
         ...mapState({
            env: state => state.env,
            user: state => state.user
         }),

         submitLabel() {
            return typeof this.sessionData !== 'undefined' ? 'Save' : 'Create'
         },

         sessionType() {
            return this.newSession.type;
         },

         isLiveSession() {
            return this.newSession.type == 'Live' && typeof this.newSession.video !== 'undefined';
         }
      },

      methods: {
         async createSession() {
            this.dateToTimestamp();

            if (typeof this.sessionData !== 'undefined' && typeof this.sessionData.id !== 'undefined') {
               console.log('setData', this.newSession)
               this.$store.dispatch('updateSession', {sessionId: this.sessionId, data: this.newSession});
            } else {
               this.sessionId = await this.$store.dispatch('createSession', this.newSession);

               // if (this.newSession.type == 'Live') {
                  var dateNow = new Date();
                  this.$store.dispatch('createSessionComments', {
                     sessionId: this.sessionId,
                     data: {
                        createdAt: fire.timestamp.fromDate(dateNow),
                        message: 'Comments created',
                        init: true
                     }
                  });
               // }
            }

            //TODO: pass files through so can upload once dispatch done
            if (typeof this.videoFile == 'object') {
               this.uploadVODFile();
               this.sessionCreated = true;
            }

            if (this.newSession.type == 'Live' && typeof this.newSession.video == 'undefined') {
               this.createLiveStream();
            }

            if (this.worksheets.files.length > 0) {
               this.uploadWorksheets();
            }

            if (this.$router.currentRoute.name !== 'Sessions' && typeof this.videoFile !== 'object') {
               this.$router.push({name: 'Sessions'});
            }
         },

         setSessionData() {
            this.newSession = Object.assign(this.newSession, this.sessionData);

            this.timestampToDate();
            this.sessionId = this.sessionData.id;
         },

         createLiveStream() {

            axios.post('https://us-central1-skillspace-app.cloudfunctions.net/MuxCreateLiveStream')
               .then((response) => {
                  var video = {
                     source: {
                        live: response.data.playback_ids[0].id
                     },
                     meta: {
                        id: response.data.id,
                        streamKey: response.data.stream_key
                     },
                     live: {
                        status: response.data.status,
                        states: {
                           recording: false,
                           connected: false,
                           disconnected: false,
                           active: false,
                           idle: true
                        }
                     },
                     asset: {
                        status: response.data.status,
                        states: {
                           live_stream_completed: false,
                           ready: false,
                           created: false
                        }
                     }
                  }
                  this.$set(this.newSession, 'video', video);

                  this.$store.dispatch('addLiveStreamData', {sessionId: this.sessionId, video: this.newSession.video});
                  this.$store.dispatch('createLiveReference', {sessionId: this.sessionId, muxId: this.newSession.video.meta.id});
               })
               .catch(error => {
                  console.log('err', error)
               });
         },

         videofileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) { return; }
            this.videoFile = files[0];
         },

         dateToTimestamp() {
            var date = new Date(this.sessionDate);
            this.newSession.scheduledFor = fire.timestamp.fromDate(date);
         },

         timestampToDate() {
            this.sessionDate = new Date(this.sessionData.scheduledFor.toDate());
         },

         uploadVODFile() {
            var self = this;
            var ref = fire.storage.ref();
            var uploadTask = ref.child('vod/' + this.videoFile.name).put(this.videoFile);

   			// Listen for state changes, errors, and completion of the upload.
   			uploadTask.on('state_changed', function(snapshot) {
   					// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
   					var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                  self.fileUploadProgress = progress.toFixed(2);
                  console.log('progress', self.fileUploadProgress)
   				},
   				function(error) {
   					// A full list of error codes is available at
   					// https://firebase.google.com/docs/storage/web/handle-errors
   				},
   				function() {
   					// Upload completed successfully, now we can get the download URL
   					uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
   						// console.log('File available at', downloadURL);
                     self.fileUploadProgress = 'Upload complete';
                     // console.log('snap', uploadTask.snapshot)

                     var uploadRef = {
                        // bucket: uploadTask.snapshot.metadata.bucket,
                        // fullPath: uploadTask.snapshot.metadata.fullPath,
                        // downloadUrl: downloadURL,
                        sessionId: self.sessionId,
                        video: {
                           source: downloadURL,
                           meta: {
                              bucket: uploadTask.snapshot.metadata.bucket,
                              fullPath: uploadTask.snapshot.metadata.fullPath,
                           }
                        }
                     }

                     self.$store.dispatch('updateSessionVODFile', uploadRef);

                     // if (this.$router.currentRoute.name !== 'Sessions') {
                     //    this.$router.push({name: 'Sessions'});
                     // }
   					});
   				});
         },

         worksheetFileChange(e) {
            var index = e.target.dataset.index;
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) { return; }
            this.worksheets.files[index].file = files[0];
         },

         uploadWorksheets() {
            if (typeof this.newSession.worksheets !== 'undefined' && this.newSession.worksheets.length > 0) {
               this.worksheets.uploaded = JSON.parse(JSON.stringify(this.newSession.worksheets))
            }

            this.worksheets.queue = [];
            this.worksheets.uploadComplete = false;

      		// add all files to the queue
      		for (var i = 0; i < this.worksheets.files.length; i++) {
      			this.worksheets.queue.push(this.worksheets.files[i]);
      		}

      		if (this.worksheets.queue.length > 1) {
      			this.processWorksheetQueue();
      		} else {
      			this.uploadWorksheetFiles(0);
      		}
         },

         processWorksheetQueue(uploadSuccess) {
            // queue counter
      		// If success then remove file from queue
      		if (uploadSuccess) {
               this.worksheets.count--;
      		}

      		while (this.worksheets.count < this.worksheets.concurrentMax) {
               this.uploadWorksheetFiles(this.worksheets.count);
               this.worksheets.count++;
      		};
         },

         uploadWorksheetFiles(itemIndex) {
            var self = this;
            var item = this.worksheets.queue[itemIndex];

            if (typeof item == 'undefined' || typeof item.file == 'undefined') {
               self.saveUploadedFiles();
               return;
            }

            var ref = fire.storage.ref();
            var uploadTask = ref.child('downloads/' + item.file.name).put(item.file);

   			// Listen for state changes, errors, and completion of the upload.
   			uploadTask.on('state_changed', function(snapshot) {
   					// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
   					var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                  // self.downloadsFileUploadProgress = progress.toFixed(2);
   				},
   				function(error) {
   					// A full list of error codes is available at
   					// https://firebase.google.com/docs/storage/web/handle-errors
   				},
   				function() {
   					// Upload completed successfully, now we can get the download URL
   					uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
   						// console.log('File available at', downloadURL);

                     var worksheet = {
                        label: item.label,
                        downloadUrl: downloadURL
                     }

                     self.worksheets.uploaded.push(worksheet);
                     self.worksheets.queue.splice(0, 1);

                     if (self.worksheets.queue.length > 0) {
                        self.processWorksheetQueue(true);
                     } else {
                        self.saveUploadedFiles();
                     }
   					});
   				});
         },

         saveUploadedFiles() {
            this.$store.dispatch('updateSessionWorksheets', {sessionId: this.sessionId, worksheets: this.worksheets.uploaded});
            this.worksheets.uploadComplete = true;
         },

         addWorksheet() {
            this.worksheets.files.push({label: '', file: ''});
         },

         goToPreview() {
            this.$router.push({ name: 'Session', params: { id: this.sessionId } });
         },

         deleteSession() {
            console.log('deleteSession', this.newSession.comments)
            if (confirm("Are you sure you want to delete this Class?")) {
              this.$store.dispatch('deleteSession', this.sessionId);
              this.$router.push({name: 'Sessions'});
            } else {
              // txt = "You pressed Cancel!";
            }
         },

         // removeDownloadFile(index) {
         //    console.log('i', index, this.downloads)
         //    this.downloads.splice(index, 1);
         // }
      }
   }
</script>
