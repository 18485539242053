<template>
   <section class="Page-panel">
      <div class="Grid">
         <div class="Grid-column">
            <h1 class="center">Subscription Plan</h1>

            <p class="mw-24 middle center mt+">Your free 7-day trial is now over. Please select a plan below to continue to our secure checkout.</p>

            <div class="LoginCard mt++">
               <div class="typeHeading-5">Weekly Subscription</div>
               <div class="typeHeading-1">Unlimited classes</div>
               <p class='mt+'>Access all our classes and features every week.</p>
               <button class="button mt++" @click="createCheckoutSession">£5.00 per week</button>
            </div>
         </div>
      </div>
   </section>
</template>

<script>
   import Vue from "vue";
   import { mapState } from 'vuex';
   import axios from 'axios';
   const fire = require('./../../firebaseConfig.js')

   var stripe = Stripe('pk_test_6lfXEefbwne0yZs3NLV9gOKR');

   export default {
      beforeRouteEnter (to, from, next) {
         next(vm => {
            if (typeof vm.user.access.sessions.status !== 'undefined' && vm.user.access.sessions.status == 'paid') {
               next({name: 'Sessions'})
            }
            else next();
         })
      },

      data() {
         return {
            sessionId: ''
         }
      },

      computed: {
         ...mapState({
            user: state => state.user,
         })
      },

      methods: {
         createCheckoutSession() {
            axios.post('https://us-central1-skillspace-app.cloudfunctions.net/CreateCheckoutSession')
               .then((response) => {
                  this.sessionId = response.data.sessionId;

                  // save session id
                  // this.$state.dispatch('saveStripeSessionId', {uid: this.user.uid, sessionId: this.sessionId});
                  fire.usersCollection.doc(this.user.uid)
                     .update({
                        "access.sessions.subscription.sessionId": this.sessionId
                     })
                     .then(() => {
                        console.log('Added session id', this.sessionId);

                        stripe.redirectToCheckout({
                          // Make the id field from the Checkout Session creation API response
                          // available to this file, so you can provide it as parameter here
                          // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
                          sessionId: this.sessionId,
                        }).then(function (result) {
                          // If `redirectToCheckout` fails due to a browser or network
                          // error, display the localized error message to your customer
                          // using `result.error.message`.
                        });
                     });
               })
               .catch(error => {
                  console.log('err', error)
               });
         }
      }
   }
</script>
