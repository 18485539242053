import * as firebase from 'firebase/app';
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'
// firebase. firestore. Timestamp

// firebase init goes here
const config = {
   apiKey: "AIzaSyBmxhU878kjsWceKnrT5n3xwfkbYYhUgmQ",
   authDomain: "skillspace-app.firebaseapp.com",
   databaseURL: "https://skillspace-app.firebaseio.com",
   projectId: "skillspace-app",
   storageBucket: "skillspace-app.appspot.com",
   appId: "1:876113997981:web:2619b8b400b6d6e8ca6474",
   measurementId: "G-HW6P19GMQ1"
}

firebase.initializeApp(config)

// firebase utils
const db = firebase.firestore()
const timestamp = firebase.firestore.Timestamp
const firestoreFieldValue = firebase.firestore.FieldValue
const auth = firebase.auth()
const storage = firebase.storage()
const currentUser = auth.currentUser
const functions = firebase.functions()

// date issue fix according to firebase
// const settings = {
//     timestampsInSnapshots: true
// }
// db.settings(settings)

// firebase collections
const usersCollection = db.collection('users')
const sessionsCollection = db.collection('sessions')
const eventsCollection = db.collection('events')
const liveStreamsCollection = db.collection('liveStreams')

export {
    db,
    timestamp,
    firestoreFieldValue,
    auth,
    currentUser,
    functions,
    storage,
    usersCollection,
    sessionsCollection,
    eventsCollection,
    liveStreamsCollection
}
