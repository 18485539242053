<template>
   <div>
      <div class="Page-navbar">
         <div class="Page-navbarWrap Navbar">
            <div class="Navbar-brand">Skillspace Admin</div>

            <ul class="Navbar-nav" v-if="!loading.isLoading">
               <!-- <li v-show="hasValidUser"><router-link to="/plans">Plans</router-link></li> -->
               <li v-show="hasValidUser && userHasAccess"><router-link to="/classes">Classes</router-link></li>
               <li v-show="hasValidUser"><a @click.prevent="signOut">Logout</a></li>
            </ul>
         </div>
      </div>

      <router-view v-if="!loading.isLoading"></router-view>

      <loading-app></loading-app>
   </div>
</template>

<script>
   import Vue from "vue"
   import {mapState} from 'vuex';
   const fire = require('./firebaseConfig.js')

   import LoadingApp from './components/loading-app.vue'

   export default Vue.extend({
      components: {
         LoadingApp
      },

      data() {
         return {
            isLoading: true
         }
      },

      computed: {
         ...mapState({
            loading: state => state.loading,
            user: state => state.user,
            sessions: state => state.sessions,
            env: state => state.env
         }),

         hasValidUser() {
            return Object.keys(this.user).length !== 0;
         },

         userHasAccess() {
            if(this.hasValidUser) {
               return this.user.uid == '5tOoGXBEpYSBKcvA4SbPiowrF492' || this.user.uid == 'DSrv4uXgUNbO10hdBvRxfzbikSp2'
            }
         },

         userSessionsAccess() {
            if(this.hasValidUser) {
               return this.user.access.sessions.status;
            }
         },
      },

      watch: {
         hasValidUser: {
            immediate: true,
            handler() {
               if (this.hasValidUser && this.sessions.length == 0) {
                  // console.log('initUser again', this.sessions.length)
                  this.initSessionsBind();
               }
            }
         }
      },

      created() {
         var self = this;

         fire.auth.onAuthStateChanged(user => {
            if (user.uid == '5tOoGXBEpYSBKcvA4SbPiowrF492' || user.uid == 'DSrv4uXgUNbO10hdBvRxfzbikSp2') {
                  // not a new user
                  this.$store.dispatch('updateLoadingState', true);
                  self.initUser(user);
               // }
            } else if (user) {
               this.signOut();
            } else {
               // no user is signed in
               if (this.$router.currentRoute.name !== 'Login') {
                  self.$router.push({ name: 'Login' });
               }
            }
         });

         if (!this.hasValidUser) {
            this.$store.dispatch('updateLoadingState', false);
         }
      },

      // mounted() {
         // this.initSessionsBind();
      // },

      methods: {
         initUser(userData) {
            // console.log('initUser', this.sessions.length)
            this.$store.dispatch('getUserDetails', userData);

            if (this.sessions.length == 0) {
               // console.log('initUser again', this.sessions.length)
               this.initSessionsBind();
            }

            if (this.$router.currentRoute.name == 'Login') {
               this.$router.push({name: 'Sessions'});
            }
         },

         initSessionsBind() {
            // console.log('initSessionsBind', this.sessions.length, this.hasValidUser)
            if (this.hasValidUser) {
               // console.log('this.hasValidUser', this.hasValidUser);
               try {
                  // console.log('initSessionsBind + env', this.env);
                  this.$store.dispatch('bindSessions')
               } catch (e) {
                  console.error(e)
               }
            }
         },

         signOut(e) {
            fire.auth.signOut();

            this.$store.dispatch('unbindSessions', this.env)
            this.$store.dispatch('updateLoadingState', false);

            if (this.$router.currentRoute.name !== 'Login') {
               this.$router.push({
                  name: "Login"
               });
            }
         }
      }
   });
</script>

<style>


</style>
