<template>
   <!-- <section class="Page-panel">
      <div class="Grid">
         <div class="Grid-column">
            <new-session></new-session>
         </div>
      </div>
   </section> -->
   <new-session :sessionData="session"></new-session>
</template>

<script>
   import Vue from "vue";
   import { mapState } from 'vuex';
   import NewSession from './../components/new-session.vue';

   export default Vue.extend({
      components: {
         NewSession
      },

      data() {
         return {}
      },

      computed: {
         ...mapState({
            user: state => state.user,
            sessions: state => state.sessions,
         }),
      },

      created() {},

      mounted() {
         // TODO: improve this
      },

      methods: {}
   });
</script>
