<template>
   <div>
      <div class="aspectVideo-message" v-show="expiry.messageVisible && expiry.message.length > 0">
         <div v-show="videoType == 'Live' && playbackType == 'Live' && video.live.status == 'idle'"><strong>Live stream not started yet</strong></div>
         {{expiry.message}}
      </div>

      <div class="" v-show="videoAvailable || previewVideo" v-if="!expiry.expired">
         <video ref="videoPlayer" class="video-js video-js vjs-default-skin vjs-big-play-centered vjs-16-9 aspectVideo" controls preload="auto">

            <p class="vjs-no-js">
               To view this video please enable JavaScript, and consider upgrading to a
               web browser that
               <a href="http://videojs.com/html5-video-support/" target="_blank">
                  supports HTML5 video
               </a>
            </p>
         </video>
      </div>
   </div>
</template>

<script>
import videojs from 'video.js';
import '@videojs/http-streaming';

export default {
   props: {
      videoSource: Object,
      poster: String,
      videoType: String,
      scheduledFor: Object,
      previewVideo: Boolean
   },

   data() {
      return {
         player: '',

         video: {
            // live - id, asset (inc status), live (inc status), streamKey
            // vod - url, type (VOD)
         },

         vjsOptions: {},

         playbackSources: {
            src: '',
            type: 'application/x-mpegurl'
         },

         expiry: {
            messageVisible: true,
            message: '',
            expired: false,
            isAvailable: false
         },

         scheduledForDate: ''
      }
   },

   computed: {
      videoAvailable() {
         if (this.videoType == 'Recorded') {
            return typeof this.video.source !== 'undefined' && this.expiry.isAvailable;
         } else {
            return this.video.live.status !== 'idle' || (this.video.asset.states.ready && this.video.asset.states.created)
         }
      },

      playbackType() {
         return this.videoType == 'Live' && (this.videoSource.live.status == 'active' || !this.videoSource.live.states.active) ? 'Live' : 'Recorded';
      },
   },

   watch: {
      videoSource: {
         immediate: true,
         handler() {
            if (typeof this.videoSource !== 'undefined') {
               this.video = Object.assign({}, this.video, this.videoSource);

               if (this.videoType == 'Live') {
                  this.vjsOptions = {liveui: true}
               } else {
                  this.vjsOptions = {};
               }


               // this.mux = Object.assign({}, this.mux, this.muxProps);
               this.setPlaybackSources()

               // if (this.muxVideoAvailable && this.player.length !== 0) {
               if ((this.videoAvailable || this.previewVideo) && this.player.length !== 0) {
                  this.setSource();
               }
            }
         }
      },

      scheduledFor: {
         immediate: true,
         handler() {
            if (this.scheduledFor !== 'undefined') {
               this.scheduledForDate = this.scheduledFor.toDate();
               this.videoAvailabilityMessage();
               this.setExpiryVisibility()
            }
         }
      }
   },

   mounted() {
      var self = this;

      if (!this.expiry.expired) {
         this.player = videojs(this.$refs.videoPlayer, this.vjsOptions, function onPlayerReady() {
            self.setupPlayer();
         })

         this.player.on('play', () => { this.playbackToggle() });
      }

      if (this.videoType == 'Recorded' && !this.expiry.expired && !this.expiry.isAvailable) {
         this.setVodAvailabilityRefresh();
      }
   },

   beforeDestroy() {
      if (this.player) {
         this.player.dispose();
      }
   },

   methods: {
      setupPlayer() {
         console.log('po', './assets/img/'+this.poster);
         this.player.poster('./assets/img/'+this.poster);

         if (this.videoAvailable || this.previewVideo) {
            this.setSource();
         }
      },

      playbackToggle() {
         this.expiry.messageVisible = false;
      },

      setPlaybackSources() {
         if (this.videoType == 'Recorded') {
            this.playbackSources.src = this.video.source;
            this.playbackSources.type = 'video/mp4'
         } else {
            var playbackId = (this.playbackType == 'Live') ? this.video.source.live : this.video.source.asset;
            this.playbackSources.src = 'https://stream.mux.com/'+playbackId+'.m3u8';
            this.playbackSources.type = 'application/x-mpegurl'
         }
      },

      setSource() {
         if (this.player.paused()) {
            console.log('setSource', this.playbackSources)
            this.player.src(this.playbackSources);
         }
      },

      formatDate(timestamp, dateFormat) {
         var date = '';

         if (timestamp) {
            date = timestamp.toDate();
            date = new Date(date);
         } else if (dateFormat) {
            date = dateFormat;
         }

         // Months array
         var months_arr = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

         // Month
         var month = months_arr[date.getMonth()];

         // Day
         var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
         var day = days[date.getDay()];
         var dayDate = date.getDate();

         // var year = date.getFullYear();

         // Display date time in day date month format
         return day+ ' '+dayDate+' '+month;

      },

      formatTime(timestamp, dateString) {
         if (typeof timestamp !== 'undefined') {
            var date = timestamp ? timestamp.toDate() : dateString;
            date = new Date(date);

            // Hours
            var hours = date.getHours();

            // Minutes
            var minutes = "0" + date.getMinutes();

            // Display date time in MM-dd-yyyy h:m:s format
            return hours + ':' + minutes.substr(-2);
         }
      },

      videoAvailabilityMessage() {
         if (typeof this.scheduledForDate == 'object') {
            var now = new Date();
            var scheduled = new Date(this.scheduledForDate);
            var expiry = new Date(this.scheduledForDate);
            var expiryDate = expiry.getDate() + 1;
            expiry.setDate(expiryDate);

            if (now < scheduled) {
               this.expiry.expired = false;
               this.expiry.message = 'Available on ' + this.formatDate(false, scheduled) + ' from ' + this.formatTime(false, this.scheduledForDate);
            } else if (now > expiry) {
               if (this.player) { this.player.dispose(); }
               this.expiry.expired = true;
               this.expiry.message = 'Sorry this video is no longer available';
            } else {
               this.expiry.isAvailable = true;
               this.expiry.expired = false;
               this.expiry.message = 'Available until ' + this.formatDate(false, expiry) + ' at ' + this.formatTime(false, this.scheduledForDate);
            }
         }
      },

      setExpiryVisibility() {
         if (typeof this.scheduledForDate == 'object') {
            var now = new Date();
            var scheduled = new Date(this.scheduledForDate);
            var expiry = new Date(this.scheduledForDate);

            if (now > scheduled && now < expiry) {
               this.expiry.isAvailable = true;
               this.expiry.message = 'Available until ' + this.formatDate(false, expiry) + ' at ' + this.formatTime(false, this.scheduledForDate);
            }
         }
      },

      setVodAvailabilityRefresh() {
         if (typeof this.scheduledForDate == 'object') {
            var self = this;
            var now = new Date();
            var scheduled = new Date(this.scheduledForDate);
            var timeDiff = (scheduled.getTime() - now.getTime()) / 1000;
            timeDiff = timeDiff * 1000;

            setTimeout(function() {
               self.setSource();
               self.expiry.isAvailable = true;
            }, timeDiff);
         }
      }
   }
}
</script>
