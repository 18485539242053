const fire = require('./../firebaseConfig.js')

import Vue from 'vue';
import Router from 'vue-router';
import Login from './../pages/login.vue';
import SignUp from './../pages/sign-up.vue';
import Sessions from './../pages/sessions.vue';
import Session from './../pages/session.vue';
import EditSession from './../pages/session-edit.vue';
import NewSession from './../pages/session-new.vue';

import Plans from './../pages/plans/index.vue';

Vue.use(Router)

const router = new Router({
   routes: [{
         path: '/login',
         name: 'Login',
         component: Login,
         meta: {
            guest: true
         }
      },
      {
         path: '/sign-up',
         name: 'Sign Up',
         component: SignUp,
         meta: {
            guest: true
         }
      },
      {
         path: '/classes',
         name: 'Sessions',
         component: Sessions,
         meta: {
            auth: true
         }
      },
      {
         path: '/classes/new',
         name: 'New Session',
         component: NewSession,
         meta: {
            auth: true
         }
      },
      {
         path: '/classes/:id',
         name: 'Session',
         component: Session,
         meta: {
            auth: true
         }
      },
      {
         path: '/classes/edit/:id',
         name: 'Edit Session',
         component: EditSession,
         meta: {
            auth: true
         }
      },
      {
         path: '/plans',
         name: 'Plans',
         component: Plans,
         meta: {
            guest: true
         }
      },
      { path: '/', redirect: { name: 'Sessions' }},
      { path: '*', redirect: { name: 'Sessions' }}
   ]
});

// more advance with optional guest access to paths: https://softauthor.com/firebaseui-vue-login-with-facebook-google-and-email-pasword
router.beforeEach((to, from, next) => {
   fire.auth.onAuthStateChanged(user => {
      if (to.name !== 'Login' && !user && !to.meta.guest) next({
         name: 'Login'
      })
      else next()
   });
})

   // router.beforeResolve((to, from, next) => {
   //    console.log('beforeResolve', store)
   // })

export default router;
