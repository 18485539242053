<template>
   <div class="Comments mw-38">
      <form class="Comments-form" @submit.prevent="postComment" v-if="!restrictChatProp">
         <div class="Form-field s-1/1">
            <input type="input" class="Form-input" placeholder="Comment..." v-model="newMessage" />
            <button class="button--s mt" @click.prevent="postComment">Post</button>
         </div>
      </form>

      <div class="Comments-list" v-if="typeof comments !== 'undefined' && comments.length > 0">
         <div class="Comments-listItem" v-for="(comment, index) in comments" v-if="typeof comment.user !== 'undefined' && (user.uid == comment.user.uid || !restrictCommentsProp)">
            <div class="Comments-listMessage">{{comment.message}}</div>
            <div class="Comments-listMeta">
               <span v-show="(user.uid !== comment.user.uid) || !restrictCommentsProp"><strong>{{comment.user.name}}</strong></span>
               {{formatDate(comment.createdAt)}} at {{formatTime(comment.createdAt)}}
            </div>
         </div>
      </div>
   </div>
</template>

<script>
   import Vue from "vue";
   import { mapState } from 'vuex';

   const fire = require('./../firebaseConfig.js')

   export default {
      props: {
         sessionIndexProp: Number,
         sessionIdProp: String,
         restrictCommentsProp: Boolean,
         restrictChatProp: Boolean
      },

      data() {
         return {
            newMessage: '',
            restrictComments: false
         }
      },

      computed: {
         ...mapState({
            user: state => state.user,
            comments: state => state.comments
         }),
      },

      watch: {
         sessionIdProp() {
            if (typeof this.sessionIdProp !=='undefined' && this.comments.length == 0) {
               this.initCommentsBind();
            }
         }
      },

      mounted() {
         if (typeof this.sessionIdProp !=='undefined') {
            this.initCommentsBind();
         }
      },

      beforeDestroy() {
         this.$store.dispatch('unbindSessionComments')
      },

      methods: {
         initCommentsBind() {
            if (typeof this.user.uid !== 'undefined' && typeof this.sessionIdProp.length > 0) {
               try {
                  this.$store.dispatch('bindSessionComments', {userUid: this.user.uid, sessionId: this.sessionIdProp})
               } catch (e) {
                  console.error(e)
               }
            }
         },

         postComment() {
            const createdAt = new Date();
            const data = {
               sessionId: this.sessionIdProp,
               comment: {
                  createdAt: fire.timestamp.fromDate(createdAt),
                  message: this.newMessage,
                  user: {
                     uid: this.user.uid,
                     name: this.user.name.first + ' ' + this.user.name.last
                  }
               }
            }

            this.$store.dispatch('postComment', data)
            this.newMessage = '';
         },

         isToday(timestamp) {
            if (typeof timestamp !== 'undefined') {
               const date = timestamp.toDate();
               const today = new Date();
               return date.getDate() == today.getDate() &&
                  date.getMonth() == today.getMonth() &&
                  date.getFullYear() == today.getFullYear()
            }
         },

         formatDate(timestamp) {
            if (typeof timestamp !== 'undefined') {
               if (this.isToday(timestamp)) {
                  return 'Today';
               }

               var date = timestamp.toDate();
               date = new Date(date);

               // Month
               const month = date.getMonth() + 1;

               // Day
               const dayDate = date.getDate();

               var year = date.getFullYear();

               // Display date time in day date month format
               return dayDate+'/'+month+'/'+year;
            }
         },

         formatTime(timestamp) {
            if (typeof timestamp !== 'undefined') {
               var date = timestamp.toDate();
               date = new Date(date);

               // Hours
               const hours = date.getHours();

               // Minutes
               const minutes = "0" + date.getMinutes();

               // Display date time in MM-dd-yyyy h:m:s format
               return hours + ':' + minutes.substr(-2);
            }
         }
      }
   }
</script>
