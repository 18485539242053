import { vuexfireMutations, firestoreAction } from 'vuexfire'

import Vue from 'vue';
import Vuex from 'vuex';

const fire = require('./firebaseConfig.js')

Vue.use(Vuex);

const store = new Vuex.Store({
   state: {
      loading: {
         isLoading: true
      },
      user: {},
      sessions: [],
      comments: [],

      env: 'live', //live || dev
   },

   mutations: {
      ...vuexfireMutations,

      setUser(state, user) {
         return state.user = Object.assign({}, state.user, user);
      },

      updateLoading(state, isLoading) {
         return Vue.set(state.loading, 'isLoading', isLoading);
      },

      updateUserStatus(state, payload) {
         return Vue.set(state.user.access[payload.type], 'status', payload.status);
      },
   },

   actions: {
      bindSessions: firestoreAction(({ bindFirestoreRef }) => {
         // return the promise returned by `bindFirestoreRef
         return bindFirestoreRef('sessions', fire.sessionsCollection
            .orderBy('scheduledFor', 'asc')
         )
      }),

      unbindSessions: firestoreAction(({ unbindFirestoreRef }) => {
         unbindFirestoreRef('sessions')
      }),

      bindSessionComments: firestoreAction(({ bindFirestoreRef }, payload) => {
         // return the promise returned by `bindFirestoreRef
         return bindFirestoreRef('comments', fire.sessionsCollection.doc(payload.sessionId).collection('comments')
            // .where('userUid', '==', payload.userUid)
            .orderBy('createdAt', 'desc')
         )
      }),

      unbindSessionComments: firestoreAction(({ unbindFirestoreRef }) => {
         unbindFirestoreRef('comments')
      }),

      getUserDetails({commit}, user) {
         var userRef = fire.usersCollection.doc(user.uid);

         return userRef.get().then(function(doc) {
            var tmpUser = {
               uid: user.uid,
               created: user.metadata.creationTime
            }
            const combinedUser = Object.assign(tmpUser, doc.data());
            // console.log('u', combinedUser);
            commit('setUser', combinedUser);
            return commit('updateLoading', false);
         });
      },

      setLoggedInUser({commit}, user) {
         console.log('setLoggedInUser', user);
         return commit('setUser', user);
      },

      createSession({commit}, data) {
         var sessionRef = fire.sessionsCollection;
         return sessionRef.add(data)
         .then((res) => {
            return res.id;
         })
         .catch((error) => {
            // handle error
            console.log('Session create error:', error);
            return;
         });
      },

      createSessionComments({commit}, payload) {
         var commentsRef = fire.sessionsCollection.doc(payload.sessionId).collection('comments');
         return commentsRef.add(payload.data)
         .then((res) => {
            return;
         })
         .catch((error) => {
            // handle error
            console.log('Comments create error:', error);
            return;
         });
      },

      updateSession({commit}, payload) {
         var sessionRef = fire.sessionsCollection.doc(payload.sessionId);
         return sessionRef.update(payload.data)
         .then(() => {
            return
         });
      },

      createNewUser({commit}, user) {
         console.log('createNewUser', user)
         var userRef = fire.usersCollection.doc(user.uid);
         var newUser = {
            name: {
               first: user.name.first,
               last: user.name.last
            },
            email: user.email,
            access: user.access, // TODO: maybe should change this??
            subscription: {
               status: 'trial'
            }
         };

         return userRef.set(newUser)
            .catch(function(error) {
               // handle error
               console.log('User save error:', error);
               return;
            });

         newUser.uid = user.uid;
         return commit('setUser', newUser);
      },

      updateLoadingState({commit}, isLoading) {
         return commit('updateLoading', isLoading);
      },

      updateUserAccess({commit}, payload) {
         var type = 'access.'+payload.type+'.status';
         console.log('type', type)

         var userRef = fire.usersCollection.doc(payload.userId);
         userRef.update({
            'access.sessions.status': payload.status,
         })
         .then(() => {
            return commit('updateUserStatus', payload)
         });
      },

      addLiveStreamData({commit}, payload) {
         console.log('addLiveStreamData', payload)
         var sessionsRef = fire.sessionsCollection.doc(payload.sessionId);
         sessionsRef.update({
            video: payload.video,
         })
         .then(() => {
            return;
         });
      },

      createLiveReference({commit}, payload) {
         console.log('createLiveReference', payload.muxId, payload.sessionId)
         var streamsRef = fire.liveStreamsCollection.doc(payload.muxId);
         streamsRef.set({
            sessionId: payload.sessionId,
         })
         .then(() => {
            return;
         });
      },

      deleteSession({commit}, sessionId) {
         fire.sessionsCollection.doc(sessionId).delete().then(function() {
             console.log("Document successfully deleted!");
         }).catch(function(error) {
             console.error("Error removing document: ", error);
         });
      },

      updateSessionVODFile({commit}, payload) {
         var sessionsRef = fire.sessionsCollection.doc(payload.sessionId);
         // sessionsRef.update({
            // VODFile: {
            //    bucket: payload.bucket,
            //    fullPath: payload.fullPath,
            //    downloadUrl: payload.downloadUrl,
            // }
         // })
         sessionsRef.update({video: payload.video})
         .then(() => {
            return;
         });
      },

      updateSessionWorksheets({commit}, payload) {
         var sessionsRef = fire.sessionsCollection.doc(payload.sessionId);
         sessionsRef.update({
            worksheets: payload.worksheets
         })
         .then(() => {
            return;
         });
      },

      postComment({commit}, payload) {
         var commentsRef = fire.sessionsCollection.doc(payload.sessionId).collection('comments');
         return commentsRef.add(payload.comment)
         .then((res) => {
            return;
         })
         .catch((error) => {
            // handle error
            console.log('Session create error:', error);
            return;
         });
      }
   }
});
export default store;
