<template>
   <video id="my-player" class="video-js video-js vjs-default-skin vjs-big-play-centered vjs-16-9 aspectVideo"
      controls preload="auto" poster="" data-setup='{}'>
      <source :src="playbackUrl" type="application/x-mpegurl"></source>
      </source>
      <p class="vjs-no-js">
         To view this video please enable JavaScript, and consider upgrading to a
         web browser that
         <a href="http://videojs.com/html5-video-support/" target="_blank">
            supports HTML5 video
         </a>
      </p>
   </video>
</template>

<script>
   import videojs from 'video.js';
   import '@videojs/http-streaming';

   export default {
      props: {
         playbackUrl: String
      },

      data() {
         return {
            player: ''
         }
      },

      mounted() {
         this.initVideo()
      },

      beforeDestroy() {
         videojs('my-player').dispose();
      },

      methods: {
         initVideo() {
            this.player = videojs('my-player', {liveui: true});
         }
      }
   }
</script>
