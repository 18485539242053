<template>
   <section class="Page-panel">
      <div class="Grid">
         <div class="Grid-column">
            <h1 class="center">Sign Up</h1>
            <p class="mw-24 middle center mt+">You will get a free 7-day trial when you sign up. At the end of this trial you will be asked to start a weekly recurring payment ....</p>

            <div class="LoginCard mt++">
               <form @submit.prevent="createNewFireUser">
                  <div class="Form-field">
                     <label for="" class="Form-label">Student's First Name</label>
                     <input type="text" class="Form-input" v-model="newUser.name.first.value" required>
                  </div>

                  <div class="Form-field">
                     <label for="" class="Form-label">Student's Last Name</label>
                     <input type="text" class="Form-input" v-model="newUser.name.last.value" required>
                  </div>

                  <div class="Form-field">
                     <label for="" class="Form-label">Email address</label>
                     <input type="email" class="Form-input" v-model="newUser.email.value" required>
                     <div class="" v-show="!newUser.email.isValid">{{ newUser.email.errorMessage }}</div>
                  </div>

                  <div class="Form-field">
                     <label for="" class="Form-label">Password</label>
                     <input type="password" class="Form-input" ref="userPassword" required>
                     <div class="" v-show="!newUser.password.isValid">{{ newUser.password.errorMessage }}</div>
                  </div>

                  <div class="Form-field LoginCard-submitWrap">
                     <input type="submit" class="Form-submit" value="Sign Up">
                  </div>
               </form>
            </div>

            <div class="mt+ center">
               Already have an account? <router-link :to="{ name: 'Login' }">Sign in</router-link>
            </div>
         </div>
      </div>
   </section>
</template>

<script>
   import Vue from 'vue';
   import { mapState } from 'vuex'
   const fire = require('./../firebaseConfig.js')

   export default Vue.extend({
      data() {
         return {
            newUser: {
               name: {
                  first: {
                     value: '',
                     isValid: true,
                     errorMessage: ''
                  },

                  last: {
                     value: '',
                     isValid: true,
                     errorMessage: ''
                  }
               },
               email: {
                  value: '',
                  isValid: true,
                  errorMessage: ''
               },
               password:{
                  isValid: true,
                  errorMessage: ''
               }
            }
         }
      },

      computed: {
         // ...mapState(['isUniqueTeamName']),
         user() {
            return this.$store.state.user;
         }
      },

      created() {
         fire.auth.onAuthStateChanged(user => {
            if (user && user.metadata.creationTime === user.metadata.lastSignInTime) {
               // if new user
               this.signUpUser(user);
            }
         });
      },

      methods: {
         createNewFireUser() {
            var self = this;

            this.newUser.email.isValid = true;
            this.newUser.password.isValid = true;

            return fire.auth.createUserWithEmailAndPassword(this.newUser.email.value, this.$refs.userPassword.value)
               .catch(function(error) {
                 var errorCode = error.code;
                 var errorMessage = error.message;

                 if (errorCode == 'auth/email-already-in-use') {
                    self.newUser.email.isValid = false;
                    self.newUser.email.errorMessage = 'Sorry, this email address is already in use';
                 }
                 else if (errorCode == 'auth/invalid-email') {
                    self.newUser.email.isValid = false;
                    self.newUser.email.errorMessage = 'Please enter a valid email address';
                 }
                 else if (errorCode == 'auth/weak-password') {
                    self.newUser.password.isValid = false;
                    self.newUser.password.errorMessage = 'Password should be at least 6 characters';
                 }
                 return;
               });
         },

         signUpUser(user) {
            this.$store.dispatch('createNewUser', {
               uid: user.uid,
               name: {
                  first: this.newUser.name.first.value,
                  last: this.newUser.name.last.value
               },
               email: user.email,
               // created: user.metadata.creationTime,
               access: {
                  sessions: {
                     status: 'trial'
                  }
               }
             });

            // TODO: Check team created
            this.$router.push({ name: 'Sessions' });
         },
      }
   });
</script>
