<template>
   <!-- <section class="Page-panel">
      <div class="Grid">
         <div class="Grid-column">
            <new-session :sessionData="session"></new-session>
         </div>
      </div>
   </section> -->
   <new-session :sessionData="session"></new-session>
</template>

<script>
   import Vue from "vue";
   import { mapState } from 'vuex';
   import NewSession from './../components/new-session.vue';

   export default Vue.extend({
      components: {
         NewSession
      },

      data() {
         return {
            sessionId: '',
            sessionIndex: 0,
         }
      },

      computed: {
         ...mapState({
            user: state => state.user,
            sessions: state => state.sessions,
         }),

         session() {
            return this.sessions[this.sessionIndex];
         },
      },

      watch: {
         session() {
            console.log('sessions change', this.session)
            this.setSession();
         }
      },

      created() {
         this.setSession();
      },

      mounted() {
         // TODO: improve this
      },

      methods: {
         setSession() {
            this.sessionId = this.$route.params.id;

            for (var i = 0; i < this.sessions.length; i++) {
               if (this.sessions[i].id == this.sessionId) {
                  this.sessionIndex = i;
                  break;
               }
            }
         }
      }
   });
</script>
