<template>
   <section class="Page-panel">
      <div class="Grid">
         <div class="Grid-column">
            <div v-show="!resetPassword.state" class="">
               <h1 class="center">Login</h1>

               <div class="LoginCard mt++">
                  <form @submit.prevent="loginSubmit">
                     <div class="Form-field">
                        <label for="" class="Form-label">Email address</label>
                        <input type="email" class="Form-input" ref="userEmail" required>
                        <div class="" v-show="!login.email.isValid">{{ login.email.errorMessage }}</div>
                     </div>

                     <div class="Form-field">
                        <label for="" class="Form-label">Password</label>
                        <input type="password" class="Form-input" ref="userPassword" required>
                        <div class="" v-show="!login.password.isValid">{{ login.password.errorMessage }}</div>
                     </div>

                     <div class="Form-field LoginCard-submitWrap">
                        <input type="submit" class="Form-submit" value="Login">
                        <a href="#" class="" @click.prevent="resetPassword.state = true">Forgot password?</a>
                     </div>
                  </form>
               </div>

               <div class="mt+ center">
                  Don’t have an account? <router-link :to="{ name: 'Sign Up' }">Sign up</router-link>
               </div>
            </div>

            <div class="LoginCard" v-show="resetPassword.state">
               <form @submit.prevent="resetPasswordSubmit" v-show="!resetPassword.emailSent">
                  <div class="Form-field">
                     <label for="" class="Form-label">Email address</label>
                     <input type="email" class="Form-input" ref="resetEmail">
                  </div>

                  <div class="Form-field">
                     <input type="submit" class="Form-submit" value="Reset">
                  </div>
               </form>

               <div class="center" v-show="resetPassword.emailSent">
                  <p>A password reset email has been sent.</p>
                  <div class="typeCopy-s mt">Please check your email and follow the instructions.</div>
               </div>
            </div>
         </div>
      </div>
   </section>
</template>

<script>
   import Vue from "vue";
   import {mapState} from 'vuex';
   const fire = require('./../firebaseConfig.js')

   export default Vue.extend({
      data() {
         return {
            resetPassword: {
               state: false,
               emailSent: false
            },

            login: {
               email: {
                  isValid: true,
                  errorMessage: '',
               },
               password: {
                  isValid: true,
                  errorMessage: '',
               }
            }
         }
      },

      computed: {
         ...mapState({
            user: state => state.user,
         }),
      },

      watch: {
         user: {
            immediate: true,
            handler() {
               if (typeof this.user.uid !== 'undefined') {
                  fire.auth.signOut();
               }
            }
         }
      },

      methods: {
         loginSubmit() {
            var self = this;
            this.login.email.isValid = true;
            this.login.password.isValid = true;

            if (this.$refs.userEmail.value !== null && this.$refs.userPassword.value !== null) {
               this.$store.dispatch('updateLoadingState', true);

               fire.auth.signInWithEmailAndPassword(this.$refs.userEmail.value, this.$refs.userPassword.value).catch(function(error) {
                 // Handle Errors here.
                 var errorCode = error.code;
                 var errorMessage = error.message;

                 // TODO: Add error handling i.e. not a user, wrong password etc...
                 if (errorCode == 'auth/invalid-email') {
                    self.login.email.isValid = false;
                    self.login.email.errorMessage = 'Please enter a valid email address';
                 }
                 else if (errorCode == 'auth/user-disabled') {
                    self.login.email.isValid = false;
                    self.login.email.errorMessage = 'Sorry this user account has been disabled';
                 }
                 else if (errorCode == 'auth/user-not-found') {
                    self.login.email.isValid = false;
                    self.login.email.errorMessage = 'User account not found';
                 }
                 else if (errorCode == 'auth/wrong-password') {
                    self.login.password.isValid = false;
                    self.login.password.errorMessage = 'Wrong password';
                 }
                 return;

                 console.log('err', errorCode, errorMessage)
               });
            }
         },

         resetPasswordSubmit() {
            var self = this;

            fire.auth.sendPasswordResetEmail(this.$refs.resetEmail.value).then(function() {
              // Email sent.
              self.resetPassword.emailSent = true;
            }).catch(function(error) {
              // An error happened.
              console.log('err', error)
            });
         }
      }
   });
</script>
